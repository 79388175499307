import { FileUploader } from './fileUploader'

export class PublicationUploader extends FileUploader {
  initUppyAws() {
    var self = this

    self.uppy.use(self.awsS3, {
      getUploadParameters(file) {
        const filename = encodeURIComponent(file.meta.name)
        const type = encodeURIComponent(file.meta.type)
        return fetch(`/publications/files/new/presign?filename=${filename}&type=${type}`).then(
          (response) => response.json(),
        )
      },
    })
  }

  setHiddenInput(file) {
    var self = this

    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    var hiddenInput = $(self.fileInput).parents('tr').find('.upload-hidden')
    $(hiddenInput).val(self.uploadedFileData(file))
  }
}
$(document).on('turbo:load', function () {
  document.querySelectorAll('.uploader-publication').forEach(function (fileInput) {
    var previewImg = $(fileInput).parents('tr.fields').children('.col-thumbnail').children('img')
    new PublicationUploader(fileInput, {
      previewImg: previewImg,
      maxFileSize: 200 * 1024 * 1024,
    })
  })

  $(document).on('nested:fieldAdded:assets', function (event) {
    var fileInput = event.field.find('.uploader-publication').last()
    var previewImg = $(fileInput).parents('tr.fields').find('.col-thumbnail img')

    new PublicationUploader(fileInput, {
      previewImg: previewImg,
      maxFileSize: 200 * 1024 * 1024,
    })
  })
})
