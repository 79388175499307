export function showAlert(message, type = 'info', duration = 0) {
  $(window).trigger('rails:flash', { type: type, message: message })
  var $thisAlert = $('.unobtrusive-flash-container .alert').first()

  if (duration > 0) {
    setTimeout(function () {
      $thisAlert.slideUp(300, function () {
        $thisAlert.remove()
      })
    }, duration)
  }

  return true
}
