import { get } from '@rails/request.js'

class AggregationSearch {
  constructor(input, args = {}) {
    this.input = input
    this.type = input.getAttribute('name')
    this.url = '/api/v2/publication-aggregations/' + this.type
    this.args = args
    this.type_query = args.type_query || ''
    this.query = args.query || ''
    this.filters = args.filters || {}
  }

  fetchData(type_query, process) {
    // Check if the query is empty or undefined
    if (!type_query || type_query.trim() === '') {
      process([])
      return
    }

    let data = {
      ...this.filters,
      type_query: type_query,
      query: this.query,
    }

    get(this.url, {
      query: data,
      responseKind: 'json',
    })
      .then((response) => {
        if (response.ok) {
          return response.json
        }
        throw new Error('Network response was not ok.')
      })
      .then((responseData) => {
        const parsedData = this.parseResponse(responseData)
        process(parsedData)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        process([])
      })
  }

  parseResponse(response) {
    if (this.type === 'year') {
      return response.map((item) => new Date(item.name).getFullYear().toString())
    } else {
      return response.map((item) => item.name)
    }
  }

  init() {
    $(this.input).typeahead({
      source: (query, process) => {
        this.fetchData(query, process)
      },
      minLength: 1,
      items: 25,
    })
  }
}

document.addEventListener('turbo:load', function () {
  document.querySelectorAll('.filter-autocomplete').forEach((input) => {
    new AggregationSearch(input).init()
  })
})
