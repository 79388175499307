function listenErrorReportingForm() {
  if ($('#account_preferences_publication_report_error_to_librarians_true').is(':checked')) {
    $('.account_preferences_publication_report_error_email').css('display', 'none')
  }
  $('.radio_buttons').click(function () {
    if ($(this).is(':checked')) {
      if ($(this).val() === 'false') {
        return $('.account_preferences_publication_report_error_email').css('display', 'block')
      } else if ($(this).val() === 'true') {
        return $('.account_preferences_publication_report_error_email').css('display', 'none')
      }
    }
  })
}

function listenHardCopiesForm() {
  if (
    $('#account_preferences_publication_order_via_inhouse_mail_to_librarians_true').is(':checked')
  ) {
    $('.account_preferences_publication_order_via_inhouse_mail_email').css('display', 'none')
  }

  $('.radio_buttons').click(function () {
    if ($(this).is(':checked')) {
      if ($(this).val() === 'false') {
        return $('.account_preferences_publication_order_via_inhouse_mail_email').css(
          'display',
          'block',
        )
      } else if ($(this).val() === 'true') {
        return $('.account_preferences_publication_order_via_inhouse_mail_email').css(
          'display',
          'none',
        )
      }
    }
  })
}

$(document).on('turbo:load', function () {
  if ($('body#accounts-edit').length) {
    listenErrorReportingForm()
    listenHardCopiesForm()
  }
})
