import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import FileInput from '@uppy/file-input'
import Informer from '@uppy/informer'
import ProgressBar from '@uppy/progress-bar'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import '@uppy/core/dist/style.css'
import '@uppy/informer/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { showAlert } from '../alertNotifications'

export class FileUploader {
  constructor(fileInput, configs = {}) {
    this.fileInput = fileInput
    this.configs = configs
    this.$previewImg = $(!!configs.previewImg ? configs.previewImg : this.generatePreviewImg())
    this.uppy = this.initUppy()
    this.awsS3 = AwsS3

    $(this.fileInput).hide() // uppy will add its own file input
    this.initUppyAws()
    this.initUppyUploadSuccess()
    this.initUppyThumbnail()
  }

  generatePreviewImg() {
    const img = document.createElement('img')
    img.src = ''
    img.width = 100
    img.className = 'img-polaroid'
    this.fileInput.after(img)

    return img
  }

  initUppy() {
    var self = this

    var uppy = new Uppy({
      id: self.fileInput.id,
      autoProceed: true,
      restrictions: {
        maxFileSize: self.configs.maxFileSize,
        allowedFileTypes: self.configs.allowedFileTypes,
      },
    })
      .use(FileInput, {
        target: $(self.fileInput).parent()[0],
      })
      .use(Informer, {
        target: self.fileInput.parentNode,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 400,
      })
      .use(ProgressBar, {
        target: self.fileInput.parentNode,
      })

    return uppy
  }

  initUppyAws() {}

  initUppyUploadSuccess() {
    var self = this

    self.uppy.on('upload-success', function (file, response) {
      self.notifyUploadSuccess(file.name)
      self.prependFilename(file.name)
      self.setHiddenInput(file)
    })
  }

  notifyUploadSuccess(fileName) {
    showAlert(fileName + ' successfully uploaded!', 'success', 5000)
  }

  prependFilename(fileName) {
    $(this.fileInput).siblings('.filename').remove()
    $(this.fileInput).before("<div class='filename'>" + fileName + '</div>')
  }

  setHiddenInput(file) {
    var self = this

    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    var hiddenInput = $(self.fileInput).parents('.form-inputs').find('.upload-hidden')
    $(hiddenInput).val(self.uploadedFileData(file))
  }

  uploadedFileData(file) {
    // construct uploaded file data in the format that Shrine expects
    return JSON.stringify({
      id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    })
  }

  initUppyThumbnail() {
    var self = this

    self.uppy.on('thumbnail:generated', function (file, preview) {
      self.$previewImg.attr('src', preview)
    })
  }
}
