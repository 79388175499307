import { showAlert } from '../alertNotifications'
import { post } from '@rails/request.js'

class AutomaticCreateForm {
  constructor(form, configs = {}) {
    this.form = form
    this.submitBtn = form.querySelector("input[type='submit']")
    this.configs = configs
  }

  initialize() {
    this.initSubmitCallback()
    this.initTypeSelector()
  }

  initTypeSelector() {
    document.addEventListener('change', (event) => {
      if (event.target.id === 'type-selector') {
        const type = event.target.value
        ;['isbn', 'doi', 'standard', 'pmid'].forEach((t) => {
          const field = document.getElementById(`${t}-field`)
          if (field) {
            field.style.display = 'none'
            field.disabled = true
            field.removeAttribute('required')
          }
        })
        const selectedField = document.getElementById(`${type}-field`)
        if (selectedField) {
          selectedField.style.display = ''
          selectedField.disabled = false
          selectedField.setAttribute('required', 'required')
        }
      }
    })
  }

  initSubmitCallback() {
    this.form.addEventListener('submit', (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.postPublication()
    })
  }

  async postPublication() {
    this.submitBtn.disabled = true
    this.submitBtn.value = 'Loading...'

    try {
      const response = await post(this.form.action, {
        body: new FormData(this.form),
        responseKind: 'json',
      })

      if (response.ok) {
        const data = await response.json
        showAlert(data.title + ' successfully created', 'success', 5000)
        this.form.reset()
        this.prependPublication(data)
      } else {
        const errorData = await response.json
        throw new Error(errorData.error.message)
      }
    } catch (error) {
      console.error(error)
      showAlert('Error: ' + error.message, 'danger')
    } finally {
      this.submitBtn.disabled = false
      this.submitBtn.value = 'Submit'
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }

  prependPublication(publication) {
    const newPublications = document.querySelector('#new-publications .media-list')
    if (newPublications) {
      newPublications.insertAdjacentHTML('afterbegin', publication.media_item_html)
    }
  }
}

document.addEventListener('turbo:load', function () {
  const form = document.getElementById('automatic-publication-form')
  if (form) {
    const automaticCreateForm = new AutomaticCreateForm(form)
    automaticCreateForm.initialize()
  }
})
