/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function () {
  if (!!$('.authorities-typeahead').length) {
    const typeaheadClass = '.authorities-typeahead'

    // limit requests frequency to 300ms
    let delay = false
    const loadTypeahead = (obj) =>
      obj.typeahead({
        source(query, process) {
          if (delay) {
            return
          }
          return $.get('/authorities.json', { q: query }, function (data) {
            delay = true
            setTimeout(function () {
              delay = false
            }, 300)
            process(data)
          })
        },
        minLength: 2,
      })

    loadTypeahead($(typeaheadClass))

    $(document).on('nested:fieldAdded:publication_authors', (event) =>
      loadTypeahead(event.field.find(typeaheadClass)),
    )
    $(document).on('nested:fieldAdded:publication_editors', (event) =>
      loadTypeahead(event.field.find(typeaheadClass)),
    )
  }
})
