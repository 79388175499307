/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbo:load', function () {
  if (
    $(
      '#publications-new_single, #publications-create, #publications-edit, #publications-update, #hard_copies-new, #hard_copies-create, #hard_copies-edit, #hard_copies-update',
    ).length
  ) {
    const typeaheadLocation = '.location-typeahead'
    const typeaheadShelfmark = '.shelfmark-typeahead'

    const loadTypeaheadLocation = (obj) =>
      obj.typeahead({
        source(query, process) {
          return $.get('/hard_copies/locations.json', { q: query }, (data) => process(data))
        },
      })
    const loadTypeaheadShelfmark = (obj) =>
      obj.typeahead({
        source(query, process) {
          return $.get('/hard_copies.json', { q: query }, (data) => process(data))
        },
        items: 3,
      })
    loadTypeaheadLocation($(typeaheadLocation))
    loadTypeaheadShelfmark($(typeaheadShelfmark))

    return $(document).on('nested:fieldAdded:hard_copies', function (event) {
      loadTypeaheadLocation(event.field.find(typeaheadLocation))
      return loadTypeaheadShelfmark(event.field.find(typeaheadShelfmark))
    })
  }
})
