import { FileUploader } from './fileUploader'

class BrandUploader extends FileUploader {
  initUppyAws() {
    var self = this

    self.uppy.use(self.awsS3, {
      getUploadParameters(file) {
        const filename = encodeURIComponent(file.meta.name)
        const type = encodeURIComponent(file.meta.type)
        return fetch(`/account/brand/presign?filename=${filename}&type=${type}`).then((response) =>
          response.json(),
        )
      },
    })
  }

  setHiddenInput(file) {
    var self = this

    // set hidden field value to the uploaded file data so that it's submitted with the form as the attachment
    var hiddenInput = $(self.fileInput).parents('.form-inputs').find('.upload-hidden')
    $(hiddenInput).val(self.uploadedFileData(file))
  }
}
$(document).on('turbo:load', function () {
  document.querySelectorAll('#uploader-brand').forEach(function (fileInput) {
    new BrandUploader(fileInput, {
      maxFileSize: 2 * 1024 * 1024,
      allowedFileTypes: ['image/jpg', '.jpg', '.jpeg', '.png', 'image/png'],
    })
  })
})
